import React, { memo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import head from 'lodash/fp/head';
import isArray from 'lodash/fp/isArray';

import Resizer from '../resizer/Resizer';
import TreeSidebar from './TreeSidebar';

import TreeCategory from './TreeCategory';

const getSidebarBodyRef = sidebarRef => head(sidebarRef.current.getElementsByClassName('AssetTreeBody'));

const getWidthInBoundaries = (minWidth, maxWidth, width) => {
    if (width > 0 && width < minWidth) {
        return minWidth;
    }
    if (width > 0 && width > maxWidth) {
        return maxWidth;
    }
    if (width > 0 && width > maxWidth) {
        return maxWidth;
    }
    return width;
};

const getCurrentCategoryElement = (children, currentCategoryId) => {
    return isArray(children) ? children.find(child => child && child.props.id === currentCategoryId) : children;
};

const renderTreesOffscreen = (children, categoryId) => {
    return React.Children.map(children, child => {
        const offscreenClasses = classNames(
            'TreeOffscreenWrapper',
            child.props.id !== categoryId && 'position-offscreen'
        );
        return <div className={offscreenClasses}>{child}</div>;
    });
};

const AssetTree = memo(props => {
    const {
        className,
        resizable,
        width,
        maxWidth,
        minWidth,
        height,
        bordered,
        currentCategoryId,
        isOpen,
        useOffscreen,
        fly,
        onCategoryChange,
        onToggleTree,
        onResizeEnd,
        children,
    } = props;

    const getSidebarMode = isFly => (isFly ? AssetTree.MODE_FLY : AssetTree.MODE_FLUID);

    const [treeWidth, setTreeWidth] = useState(parseInt(width, 10));
    const [isResize, setIsResize] = useState(false);
    const [sidebarMode, setSidebarMode] = useState(getSidebarMode(fly));

    const sidebarRef = useRef();

    // Update internal state when props change
    useEffect(() => setTreeWidth(width), [width]);
    useEffect(() => setSidebarMode(getSidebarMode(fly)), [fly]);

    const classes = classNames(
        'AssetTree',
        className,
        !isOpen && 'closed',
        bordered && 'panel panel-default',
        sidebarMode === AssetTree.MODE_FLY ? 'fly' : 'fluid'
    );

    const resizeLimitClasses = classNames('AssetTreeResizeLimit', isResize && 'display-block');

    const resizeIndicatorPosition = maxWidth || window.innerWidth * 0.5;
    const resizeLimitStyle = { left: resizeIndicatorPosition };

    const categoryId = currentCategoryId || 0;

    const firstChild = head(children);

    const category = currentCategoryId ? getCurrentCategoryElement(children, currentCategoryId) : firstChild;

    const style = {
        width: treeWidth,
        height: height,
    };

    const handleToggleTreeContent = () => onToggleTree(!isOpen);

    const handleSelectCategory = selectedCategoryId => {
        onCategoryChange(selectedCategoryId);

        if (!isOpen) {
            handleToggleTreeContent();
        } else if (isOpen && currentCategoryId === selectedCategoryId) {
            handleToggleTreeContent();
        }
    };

    const handleResize = diff => {
        const halfWindowWidth = window.innerWidth * 0.5;

        const usedMaxWidth = maxWidth || halfWindowWidth;
        const updatedWidth = treeWidth - diff;

        const newWidth = getWidthInBoundaries(minWidth, usedMaxWidth, updatedWidth);

        // Check for sidebar with if it is half window size. If it was before but the sidebar was resized so it is
        // no longer walf window size, set the sidebar with to hals window size to avoid jumping sidebar to old width
        setTreeWidth(newWidth);
    };

    const handleResizeStart = () => {
        const body = getSidebarBodyRef(sidebarRef);
        if (body) {
            body.classList.add('pointer-events-none');
        }
        setIsResize(true);
    };

    const handleResizeEnd = () => {
        const body = getSidebarBodyRef(sidebarRef);
        if (body) {
            body.classList.remove('pointer-events-none');
        }
        setIsResize(false);
        onResizeEnd();
    };

    return (
        <div className={classes} style={style} ref={sidebarRef}>
            <div className={resizeLimitClasses} style={resizeLimitStyle} />
            <div className={'AssetTreeContent'}>
                <TreeSidebar
                    onSelectCategory={handleSelectCategory}
                    currentCategoryId={currentCategoryId}
                    onClick={handleToggleTreeContent}
                >
                    {isArray(children) ? children : [children]}
                </TreeSidebar>
                <div className={'AssetTreeBody'}>
                    {useOffscreen ? renderTreesOffscreen(children, categoryId) : category}
                </div>
            </div>
            {resizable && isOpen && (
                <Resizer
                    onResizeStart={handleResizeStart}
                    onResize={handleResize}
                    onResizeEnd={handleResizeEnd}
                    direction={Resizer.HORIZONTAL}
                    position={Resizer.RIGHT}
                />
            )}
        </div>
    );
});

AssetTree.displayName = 'AssetTree';

AssetTree.MODE_FLY = 'fly';
AssetTree.MODE_FLUID = 'fluid';

AssetTree.defaultProps = {
    width: 350,
    minWidth: 100,
    maxWidth: 0,
    resizable: true,
    disableEsc: false,
    bordered: false,
    fly: false,
    isOpen: true,
    onToggleTree: () => {},
    children: [],
    onCategoryChange: () => {},
    onResizeEnd: () => {},
    useOffscreen: false,
};

AssetTree.propTypes = {
    fly: PropTypes.bool,
    resizable: PropTypes.bool,
    bordered: PropTypes.bool,
    // When sidebar is resizable it will take the provided width in px only
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    height: PropTypes.number,
    isOpen: PropTypes.bool,
    onToggleTree: PropTypes.func,
    currentCategoryId: PropTypes.string.isRequired,
    onCategoryChange: PropTypes.func,
    className: PropTypes.string,
    onResizeEnd: PropTypes.func,
    useOffscreen: PropTypes.bool,
    children: (props, propName, componentName) => {
        const prop = props[propName];
        let error = null;
        React.Children.forEach(prop, child => {
            if (child.type !== TreeCategory) {
                error = new Error(`\`${componentName}\` children should be of type \`TreeCategory\`.`);
            }
        });
        return error;
    },
};

export default AssetTree;
