// This is the runtime configuration being used for production
// where authorization is done via the `authorization-server`.

const clientId = '8c10dd09-422c-4da3-b1d4-96d66d6428be';
const authority = 'https://login.microsoftonline.com/7e4c72b8-86d8-4e3a-90d8-4f7b7b2513c2';
const oauthService = `${authority}/oauth2/v2.0`;
const appUri = 'https://admin-latam.rio.cloud';
const redirectUri = `${appUri}/redirect.html`;

export const productionLATAMConfig = {
    id: 'env.production.latam',
    backend: {
        AUTHENTICATION_SERVICE: oauthService,
    },
    homeRoute: 'https://admin-latam.rio.cloud',
    appMenuUri: 'https://admin-latam.rio.cloud/#app-menu',
    userMenuUri: 'https://admin-latam.rio.cloud/#user-menu',
    login: {
        authority: authority,
        clientId: clientId,
        resource: clientId,
        mockAuthorization: false,
        preventRedirect: false,
        redirectUri: redirectUri,
        silentRedirectUri: redirectUri,
    },
    logoutUri: 'https://login.microsoftonline.com/7e4c72b8-86d8-4e3a-90d8-4f7b7b2513c2/oauth2/v2.0/logout?redirect_uri=https://admin-latam.rio.cloud',
};
