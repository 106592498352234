// This is the runtime configuration being used for accessing
// production data from a local frontend build

export const localProdConfig = {
    id: 'env.localprod',
    backend: {
        AUTHENTICATION_SERVICE: 'https://auth.iam.rio.cloud/oauth',
    },
    homeRoute: `${window.location.origin}`,
    appMenuUri: 'https://admin-eu.rio.cloud/#app-menu',
    userMenuUri: 'https://admin-eu.rio.cloud/#user-menu',
    login: {
        authority: 'https://auth.iam.rio.cloud',
        oauthScope: [
            'openid',
            'profile',
            'email',
            'user-managment.read',
        ],
        mockAuthorization: true,
        preventRedirect: false,
        redirectUri: 'https://admin-eu.rio.cloud/redirect.html',
        silentRedirectUri: 'https://admin-eu.rio.cloud/redirect.html',
    },
    logoutUri: 'https://login.microsoftonline.com/7e4c72b8-86d8-4e3a-90d8-4f7b7b2513c2/oauth2/v2.0/logout?redirect_uri=https://admin-eu.rio.cloudt',
};

