import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Dialog from './Dialog';
import baseDialogPropTypes from './baseDialogPropTypes';

/*
 * Component to show a modal popup containing a title, split content and a close handler.
 */
const SplitDialog = props => {
    const {
        leftContent,
        rightContent,
        onClose,
        className,
    } = props;

    const dialogClassName = `split-dialog ${className}`;

    const dividerClasses = classNames(
        'split-divider',
        'position-absolute',
        'padding-bottom-1-xs',
        'padding-right-1-ls',
        'bottom-0',
        'left-0-xs',
        'right-0',
        'top-0-ls',
        'bg-lighter'
    );

    const body = (
        <div className={'split-wrapper display-flex-ls'}>
            <div className={'split-left padding-bottom-10-xs margin-bottom-20-xs'}>
                {leftContent}
                <div className={dividerClasses}></div>
            </div>
            <div className={'split-right flex-1-1-ls padding-left-20-ls'}>{rightContent}</div>
        </div>
    );

    return (
        <Dialog
            {...props}
            body={body}
            onHide={onClose}
            className={dialogClassName}
        />
    );
};

SplitDialog.defaultProps = {
    className: '',
    footer: '',
    useOverflow: true,
    showCloseButton: true,
};

SplitDialog.propTypes = {
    ...baseDialogPropTypes,
    leftContent: PropTypes.node,
    rightContent: PropTypes.node,
    bodyClassName: PropTypes.string,
    footerClassName: PropTypes.string,
    footer: PropTypes.node,
};

export default SplitDialog;
