import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export const TableSettingsDialogFooter = props => {
    const {
        hasChanged,
        isResetAll,
        immediateChange,
        resetButtonText,
        onHide,
        closeButtonText,
        cancelButtonText,
        applyButtonText,
        onResetColumnChanges,
        onConfirmResetColumnChanges,
        onCancelResetColumnChanges,
        onDiscardChanges,
        onApplyChanges,
    } = props;

    const restButtonClassNames = classNames('btn', 'btn-link', !hasChanged && 'disabled');

    return (
        <div>
            <div className={'pull-left'}>
                {!isResetAll && (
                    <div className={restButtonClassNames} onClick={onResetColumnChanges}>
                        <span className={'rioglyph rioglyph-revert text-size-small margin-right-10'}></span>
                        {resetButtonText}
                    </div>
                )}
                {isResetAll && (
                    <div className={'btn-group'}>
                        <div
                            className={'btn btn-icon-only btn-default margin-right-5 btn-outline'}
                            onClick={onCancelResetColumnChanges}
                        >
                            <span className={'rioglyph rioglyph-remove'}></span>
                        </div>
                        <div className={'btn btn-primary btn-icon-only'} onClick={onConfirmResetColumnChanges}>
                            <span className={'rioglyph rioglyph-ok'}></span>
                        </div>
                    </div>
                )}
            </div>
            {immediateChange ? (
                <div className={'pull-right'}>
                    <div className={'btn btn-default'} onClick={onHide}>
                        {closeButtonText}
                    </div>
                </div>
            ) : (
                <div className={'pull-right'}>
                    <div className={'btn btn-default'} onClick={onDiscardChanges}>
                        {cancelButtonText}
                    </div>
                    <div className={'btn btn-primary'} onClick={onApplyChanges}>
                        {applyButtonText}
                    </div>
                </div>
            )}
        </div>
    );
};

TableSettingsDialogFooter.defaultProps = {
    hasChanged: false,
    isResetAll: false,
    immediateChange: false,
    resetButtonText: '',
    closeButtonText: '',
    cancelButtonText: '',
    applyButtonText: '',
    onHide: () => undefined,
    onResetColumnChanges: () => undefined,
    onDiscardChanges: () => undefined,
    onApplyChanges: () => undefined,
};

TableSettingsDialogFooter.propTypes = {
    hasChanged: PropTypes.bool,
    isResetAll: PropTypes.bool,
    immediateChange: PropTypes.bool,
    resetButtonText: PropTypes.node,
    closeButtonText: PropTypes.node,
    cancelButtonText: PropTypes.node,
    applyButtonText: PropTypes.node,
    onHide: PropTypes.func,
    onResetColumnChanges: PropTypes.func,
    onDiscardChanges: PropTypes.func,
    onApplyChanges: PropTypes.func,
};
