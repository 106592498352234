import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/fp/map';

import { excludeFromList } from './Tree';
import TreeLeaf from './TreeLeaf';

const TreeLeafList = props => {
    const { leafList, hasMultiselect, selectedItems, selectedGroups, onSelectionChange } = props;

    const handleItemSelection = itemId => {
        const isSelected = selectedItems.includes(itemId);
        const newSelectedItems = isSelected ? excludeFromList(selectedItems, itemId) : [...selectedItems, itemId];

        onSelectionChange(newSelectedItems, selectedGroups);
    };

    const handleSetItemActive = itemId => onSelectionChange([itemId], []);

    return map(item => {
        return (
            <TreeLeaf
                key={item.id}
                item={item}
                hasMultiselect={hasMultiselect}
                isSelected={selectedItems.includes(item.id)}
                onSelectItem={() => handleItemSelection(item.id)}
                onActiveItem={() => handleSetItemActive(item.id)}
            />
        );
    })(leafList);
};

TreeLeafList.displayName = 'TreeLeafList';

TreeLeafList.propTypes = {
    leafList: PropTypes.arrayOf(PropTypes.object),
    hasMultiselect: PropTypes.bool,
    selectedItems: PropTypes.arrayOf(PropTypes.string),
    selectedGroups: PropTypes.arrayOf(PropTypes.string),
    onSelectionChange: PropTypes.func,
};

export default TreeLeafList;
