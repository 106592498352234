import includes from 'lodash/fp/includes';
import replace from 'lodash/fp/replace';

export const fixUrl = url => {
    const { host } = window.location;
    if (includes('admin-eu', host)) {
        return replace('{region}', 'eu', url);
    }
    if (includes('admin-latam', host)) {
        return replace('{region}', 'latam', url);
    }
    return url;
};
