import React from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/fp/omit';
import isNil from 'lodash/fp/isNil';
import isEmpty from 'lodash/fp/isEmpty';
import classNames from 'classnames';

export const DEFAULT_TYPE = 'text';
export const SUPPORTED_TYPES = ['text', 'password', 'email'];

class ClearableInput extends React.Component {
    constructor(props) {
        super(props);

        const initialValue = props.value || props.defaultValue || '';

        this.state = {
            inputValue: initialValue,
            showClear: this.hasValue(initialValue),
            // Note: check for null and undefined not for empty string
            isControlled: !isNil(props.value),
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.clearInputValue = this.clearInputValue.bind(this);
    }

    // only gets triggered on user interaction.
    handleChange(event) {
        const { isControlled } = this.state;
        const { onChange } = this.props;

        const value = event.target.value;

        if (isControlled) {
            onChange(value, event);
        } else {
            this.changeInternalValue(value, event);
            onChange(value, event);
        }
    }

    // Will be triggered on every key press but also when pressing 'Enter' for example
    handleKeyPress(event) {
        const { onKeyPress } = this.props;
        onKeyPress(event);
    }

    // important for when used as a controlled component (value from outside).
    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.changeInternalValue(nextProps.value);
    }

    hasValue(value) {
        return !isEmpty(`${value}`);
    }

    // handles new input value and saves it in the state.
    // the value stored in the state is used for rendering.
    changeInternalValue(newValue = '') {
        // this is there to prevent that onChange and UNSAFE_componentWillReceiveProps set state at the same time
        if (this.state.inputValue === newValue) {
            return;
        }

        this.setState(() => ({
            inputValue: newValue,
            showClear: this.hasValue(newValue),
        }));
    }

    clearInputValue(event) {
        const { onChange, onClear } = this.props;

        this.changeInternalValue('', event);

        onChange('', event);

        if (onClear) {
            onClear(event);
        }
    }

    // it is always the value from the state which is used for rendering.
    // reason: make it possible to use the component as a controlled or uncontrolled component.
    // https://facebook.github.io/react/docs/uncontrolled-components.html
    render() {
        const filteredProps = omit(['value', 'defaultValue', 'onClear']);

        const {
            children,
            className,
            inputClassName,
            type,
            hasError,
            inputRef,
            autoComplete,
            ...customProps
        } = this.props;

        const { showClear, inputValue } = this.state;

        const classes = classNames('ClearableInput', 'input-group', hasError && 'has-error', className && className);

        const inputClassNames = classNames('form-control', inputClassName, showClear && 'withClearButton');

        const clearButtonClassNames = classNames('clearButton', !showClear && 'hide');

        const convertedType = type && type.toLowerCase();
        const inputType = SUPPORTED_TYPES.indexOf(convertedType) !== -1 ? convertedType : DEFAULT_TYPE;

        const inputProps = {
            ...filteredProps(customProps),
            className: inputClassNames,
            type: inputType,
            value: inputValue,
            onChange: this.handleChange,
            onKeyPress: this.handleKeyPress,
            ref: inputRef,
            autoComplete,
        };

        return (
            <div className={classes}>
                {children ? children(inputProps) : <input {...inputProps} />}
                <span className={clearButtonClassNames} onClick={this.clearInputValue}>
                    <span className={'clearButtonIcon rioglyph rioglyph-remove-sign'}></span>
                </span>
            </div>
        );
    }
}

ClearableInput.defaultProps = {
    type: DEFAULT_TYPE,
    className: '',
    inputClassName: '',
    hasError: false,
    tabIndex: 0,
    onChange: () => {},
    onBlur: () => {},
    onFocus: () => {},
    onKeyPress: () => {},
};

ClearableInput.propTypes = {
    className: PropTypes.string,
    inputClassName: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onClear: PropTypes.func,
    onKeyPress: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    type: PropTypes.oneOf(SUPPORTED_TYPES),
    defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    maxLength: PropTypes.number,
    tabIndex: PropTypes.number,
    hasError: PropTypes.bool,
    inputRef: PropTypes.func,
    autoComplete: PropTypes.string,
};

export default ClearableInput;
