import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import NumberInput from '../numberInput/NumberInput';

const INITIAL_TICK = 200;
const TICK_TIME = 50;

export default class NumberControl extends Component {
    constructor() {
        super();
        this.isHolding = false;
        this.timeOutFunction = undefined;
    }

    increment() {
        if (this.isHolding && !this.props.disabled) {
            this.numberInput.incrementValue();
            this.timeOutFunction = setTimeout(() => {
                this.increment();
            }, TICK_TIME);
        }
    }

    decrement() {
        if (this.isHolding && !this.props.disabled) {
            this.numberInput.decrementValue();
            this.timeOutFunction = setTimeout(() => {
                this.decrement();
            }, TICK_TIME);
        }
    }

    onIncrement() {
        if (!this.props.disabled) {
            this.isHolding = true;
            this.numberInput.incrementValue();

            clearTimeout(this.timeOutFunction);
            this.timeOutFunction = setTimeout(() => {
                this.increment();
            }, INITIAL_TICK);
        }
    }

    onDecrement() {
        if (!this.props.disabled) {
            this.isHolding = true;
            this.numberInput.decrementValue();

            clearTimeout(this.timeOutFunction);
            this.timeOutFunction = setTimeout(() => {
                this.decrement();
            }, INITIAL_TICK);
        }
    }

    stopHolding() {
        this.isHolding = false;
    }

    render() {
        const { min, max, value, step, onValueChanged, disabled, bsSize, className, unit, inputAddon } = this.props;

        const classes = classNames('NumberControl', 'form-group', className);

        const inputGroupClassNames = classNames(
            'input-group',
            bsSize === 'sm' && 'input-group-sm',
            bsSize === 'lg' && 'input-group-lg'
        );

        return (
            <div className={classes}>
                <div className={inputGroupClassNames}>
                    {inputAddon &&
                        <span className={'input-group-addon'}>
                            <span className={inputAddon}></span>
                        </span>
                    }
                    <NumberInput
                        min={min}
                        max={max}
                        value={value}
                        step={step}
                        disabled={disabled}
                        onValueChanged={onValueChanged}
                        ref={child => {
                            this.numberInput = child;
                        }}
                    />
                    <div className={'input-group-addon'}>
                        {unit && <div className={'margin-right-5'}>{unit}</div>}
                        <div
                            onMouseDown={() => this.onDecrement()}
                            onMouseUp={() => this.stopHolding()}
                            className={'text-color-gray hover-text-color-dark'}
                        >
                            <div className={'rioglyph rioglyph-minus scale-90'} />
                        </div>
                        <div
                            onMouseDown={() => this.onIncrement()}
                            onMouseUp={() => this.stopHolding()}
                            className={'text-color-gray hover-text-color-dark margin-left-5'}
                        >
                            <div className={'rioglyph rioglyph-plus scale-90'} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

NumberControl.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
    step: PropTypes.number,
    disabled: PropTypes.bool,
    onValueChanged: PropTypes.func,
    bsSize: PropTypes.string,
    className: PropTypes.string,
    unit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    inputAddon: PropTypes.string,
};
