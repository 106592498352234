import map from 'lodash/fp/map';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { fetchApps } from '~/features/apps/actions/appsActions';

import { getApps } from '~/features/apps/selectors/appsSelectors';

import { fixUrl } from '~/features/apps/utils/appsUtils';

export class ApplicationMenu extends PureComponent {
    constructor(props) {
        super(props);
        props.fetchApps();
    }

    componentDidMount() {
        this.sendDocumentSize();
    }

    componentDidUpdate() {
        this.sendDocumentSize();
    }

    sendDocumentSize() {
        if (document.referrer) {
            const height = document.body.clientHeight;
            window.parent.postMessage({ height: height }, document.referrer);
        }
    }

    render() {
        const { apps } = this.props;
        return (
            <div className='bg-white padding-bottom-5'>
                {this.renderAppicationMenu(apps)}
            </div>
        );
    }

    renderAppicationMenuItem(app) {
        return (
            <a className='hover-text-decoration-none' role='menuitem' href={fixUrl(app.url)} target='_top'>
                <div className='padding-10 padding-left-20 text-color-darker hover-bg-lightest'>
                    <FormattedMessage id={app.name}/>
                </div>
            </a>
        );
    }
    renderAppicationMenu(apps) {
        return map(this.renderAppicationMenuItem, apps);
    }
}

export const mapStateToProps = (state) => ({
    apps: getApps(state),
});

export const mapDispatchToProps = (dispatch) => ({
    fetchApps: () => dispatch(fetchApps()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationMenu);

ApplicationMenu.defaultProps = {
    apps: [],
};

ApplicationMenu.propTypes = {
    apps: PropTypes.array,
};
