import '~/setup/polyfills';

import React from 'react';
import { render } from 'react-dom';
import { Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';

import * as Sentry from '@sentry/browser';

import { ConnectedRouter } from 'connected-react-router';

import { configureReporting } from '~/setup/errorReporting';
import { history, store } from '~/setup/store';

import { getLocale } from '~/features/lang/selectors';
import { extractLanguage } from '~/features/lang/lang';
import { configureFetchLanguageData } from '~/features/lang/services';
import {
    configureUserManager,
    configureMockUserManager,
    createUserManager,
} from '~/features/login/login';
import {
    userProfileObtained,
    userSessionExpired,
    userSessionRenewed,
} from '~/features/login/actions';
import { accessToken } from '~/features/tokenHandling/accessToken';
import { accessTokenStored, idTokenStored } from '~/features/tokenHandling/actions';

import { env } from '~/env';

import ApplicationMenu from '~/features/apps/components/ApplicationMenu';
import UserMenu from '~/features/base/components/UserMenu';
import MainContainer from '~/features/base/components/MainContainer';

import { NoMatch } from '~/features/base/components/NoMatch';
import IntlContainer from '~/features/base/components/IntlContainer';
import { attemptInitialSignIn } from './setup/oauth';

const main = () => {
    const root = document.querySelector('#root');
    let renderApp = () => {
        renderApp = () => {};

        render(
            <Provider store={store}>
                <IntlContainer>
                    <ConnectedRouter history={history}>
                        <Switch>
                            <Route path='/error' component={NoMatch}/>
                            <Route path='/app-menu' component={ApplicationMenu}/>
                            <Route path='/account-menu' component={UserMenu}/>
                            <Route path='/user-menu' component={UserMenu}/>
                            <Route path='/' component={MainContainer}/>
                            <Route component={NoMatch}/>
                        </Switch>
                    </ConnectedRouter>
                </IntlContainer>
            </Provider>,
            root
        );
    };

    const fetchLanguageData = configureFetchLanguageData(store);

    // We want the `<html lang>` attribute to be synced with the
    // language currently displayed
    store.subscribe(() => {
        const lang = extractLanguage(getLocale(store.getState()));
        const html = document.querySelector('html');

        if (html.getAttribute('lang') !== lang) {
            html.setAttribute('lang', lang);
        }
    });

    const oauthConfig = {
        onSessionExpired: () => {
            accessToken.discardAccessToken();
            store.dispatch(userSessionExpired());
        },
        onSessionRenewed: result => {
            accessToken.saveAccessToken(result.accessToken);
            store.dispatch(accessTokenStored(result.accessToken));
            store.dispatch(idTokenStored(result.idToken));
            store.dispatch(userProfileObtained(result.profile));

            store.dispatch(userSessionRenewed());

            // You will need to get the user language by yourself then
            // you may fetch the suitable messages from the CDN. Depending
            // on when and from where you fetch the user settings you might
            // want to employ a loading spinner while the request is ongoing.
            fetchLanguageData(result.locale);
        },
    };

    const isAllowedToMockAuth = process.env.NODE_ENV !== 'production';
    const userManager =
        isAllowedToMockAuth && env.runtimeConfig.login.mockAuthorization
            ? configureMockUserManager(oauthConfig)
            : configureUserManager(oauthConfig, createUserManager());

    attemptInitialSignIn(userManager)
        .then(renderApp)
        .catch(error => {
            console.error('Could not start application', error);
        });

};

main();
