import React from 'react';
import { withRouter, Route, Redirect, Switch } from 'react-router-dom';

import ApplicationContentWrapper from '~/features/base/components/ApplicationContentWrapper';
import SubModuleContainer from '~/features/base/components/SubModuleContainer';

// Primary routes
import StartScreen from '~/features/base/components/StartScreen';

export const getRoutes = ({ match }) => {
    return (
        <ApplicationContentWrapper>
            <SubModuleContainer>
                <Switch>
                    <Route path={`${match.path}`} component={StartScreen} />
                    <Redirect to={`${match.url}`} />
                </Switch>
            </SubModuleContainer>
        </ApplicationContentWrapper>
    );
};

export const routes = withRouter(getRoutes);

export default routes;
