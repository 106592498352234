// This is the runtime configuration being used for production
// where authorization is done via the `authorization-server`.

const clientId = '395d6c94-7085-4068-9cfa-021cfe60b30d';
const authority = 'https://login.microsoftonline.com/7e4c72b8-86d8-4e3a-90d8-4f7b7b2513c2';
const oauthService = `${authority}/oauth2/v2.0`;
const appUri = 'https://admin-eu.rio.cloud';
const redirectUri = `${appUri}/redirect.html`;

export const productionConfig = {
    id: 'env.production',
    backend: {
        AUTHENTICATION_SERVICE: oauthService,
    },
    homeRoute: 'https://admin-eu.rio.cloud',
    appMenuUri: 'https://admin-eu.rio.cloud/#app-menu',
    userMenuUri: 'https://admin-eu.rio.cloud/#user-menu',
    login: {
        authority: authority,
        clientId: clientId,
        resource: clientId,
        mockAuthorization: false,
        preventRedirect: false,
        redirectUri: redirectUri,
        silentRedirectUri: redirectUri,
    },
    logoutUri: 'https://login.microsoftonline.com/7e4c72b8-86d8-4e3a-90d8-4f7b7b2513c2/oauth2/v2.0/logout?redirect_uri=https://admin-eu.rio.cloud',
};
