// This is the runtime configuration being used for tests

export const testConfig = {
    id: 'env.test',
    backend: {
    },
    homeRoute: `${window.location.origin}`,
    appMenuUri: `${window.location.origin}/#app-menu`,
    login: {
        authority: `${window.location.origin}/oauth`,
        mockAuthorization: false,
        preventRedirect: false,
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
    },
    logoutUri: `${window.location.origin}`,
};

