import React from 'react';
import CustomState from './CustomState';
import PropTypes from 'prop-types';
import isArray from 'lodash/fp/isArray';
import isEmpty from 'lodash/fp/isEmpty';

const NotBookedState = props => {
    const { message, features, ...remainingProps } = props;

    const combinedMessages = (
        <React.Fragment>
            {message && <div className={'text-medium margin-bottom-20'}>{message}</div>}
            {features && isArray(features) && !isEmpty(features) && (
                <ul className={'feature-list'}>
                    {features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                    ))}
                </ul>
            )}
        </React.Fragment>
    );

    return <CustomState message={combinedMessages} {...remainingProps} />;
};

NotBookedState.defaultProps = {
    icon: 'rioglyph-stars',
};

NotBookedState.propTypes = {
    headline: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    features: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.node])),
    buttons: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
            className: PropTypes.string,
            onClick: PropTypes.func,
            href: PropTypes.string,
        })
    ),
    condensed: PropTypes.bool,
};

export default NotBookedState;
