import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class ToggleButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggled: props.active,
        };

        this.handleClick = this.handleClick.bind(this);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            toggled: nextProps.active,
        });
    }

    handleClick() {
        const isToggled = !this.state.toggled;

        this.setState({
            toggled: isToggled,
        });

        this.props.onClick(isToggled);

        if (this.toggleRef) {
            this.toggleRef.blur();
        }
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        const { active, className, bsStyle, children, ...remainingProps } = this.props;
        const { toggled } = this.state;

        const buttonClassNames = classNames('ToggleButton', 'btn', `btn-${bsStyle}`, toggled && 'active', className);

        return (
            <button
                ref={node => (this.toggleRef = node)}
                type={'button'}
                {...remainingProps}
                className={buttonClassNames}
                onClick={this.handleClick}
            >
                {children}
            </button>
        );
    }
}

ToggleButton.defaultProps = {
    active: false,
    onClick: () => {},
    className: '',
    bsStyle: 'default',
};

ToggleButton.propTypes = {
    active: PropTypes.bool,
    bsStyle: PropTypes.oneOf(['default', 'primary', 'info', 'warning', 'danger', 'success']),
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
};
