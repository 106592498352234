// This is the runtime configuration being used for development
// against a local instance of an `authorization-server`. Note
// that the redirect only works if you run the `webpack-dev-server`
// on http://localhost:8081.

export const localAuthConfig = {
    id: 'env.localauth',
    backend: {
    },
    homeRoute: `${window.location.origin}`,
    appMenuUri: `${window.location.origin}/#app-menu`,
    userMenuUri: `${window.location.origin}/#user-menu`,
    login: {
        authority: 'http://localhost:8443',
        clientId: 'test-client',
        mockAuthorization: true,
        preventRedirect: false,
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
    },
    logoutUri: `http://localhost:8443/logout?redirect_uri=${window.location.origin}`,
};
