/* eslint-disable complexity, no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Resizer from '../resizer/Resizer';
import classNames from 'classnames';
import throttle from 'lodash/fp/throttle';
import isNil from 'lodash/fp/isNil';
import head from 'lodash/fp/head';
import addEventListener from '../../utils/addEventListener';
import ownerDocument from '../../utils/ownerDocument';
import logDeprecatedWarnings from '../../utils/logDeprecatedWarnings';

const deprecatedProps = [
    {
        prop: 'disableFullscreen',
        replacement: 'enableFullscreenToggle',
    },
    {
        prop: 'fullscreen',
        replacement: 'openInFullscreen',
    },
];

const renderFooter = (footer, footerClassName) => {
    const footerClassNames = classNames('SidebarFooter', footerClassName && footerClassName);

    return <div className={footerClassNames}>{footer}</div>;
};

const RESIZE_THROTTLE = 500;

const getSidebarBodyByRef = sidebarRef => head(sidebarRef.getElementsByClassName('SidebarBody'));

class Sidebar extends Component {
    constructor(props) {
        super(props);

        const { width, fly, fullscreen, openInFullscreen } = this.props;

        const shouldOpenInFullscreen = !isNil(fullscreen) ? fullscreen : openInFullscreen;

        this.state = {
            width,
            sidebarMode: fly ? Sidebar.MODE_FLY : Sidebar.MODE_FLUID,
            isFullscreen: shouldOpenInFullscreen,
        };

        this.handleResizeStart = this.handleResizeStart.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.handleResizeEnd = this.handleResizeEnd.bind(this);
        this.handleFullscreenChange = this.handleFullscreenChange.bind(this);
        this.handleEscFullscreen = this.handleEscFullscreen.bind(this);

        this.onWindowResize = throttle(RESIZE_THROTTLE, this.onWindowResize.bind(this));
    }

    componentDidMount() {
        const { resizable, width, switchModeBreakpoint } = this.props;

        this.setState({
            width: resizable ? parseInt(width, 10) : width,
        });

        if (switchModeBreakpoint > 0) {
            this.resizeListener = addEventListener(window, 'resize', this.onWindowResize);

            // Initially check breakpoint after mounting the component
            this.adaptSidebarMode();
        }

        const doc = ownerDocument(this);
        this.onDocumentKeydownListener = addEventListener(doc, 'keydown', this.handleEscFullscreen);
    }

    componentWillUnmount() {
        if (this.resizeListener) {
            this.resizeListener.remove();
        }
        if (this.onDocumentKeydownListener) {
            this.onDocumentKeydownListener.remove();
        }
    }

    onWindowResize() {
        this.adaptSidebarMode();
    }

    adaptSidebarMode() {
        if (window.innerWidth <= this.props.switchModeBreakpoint) {
            this.setState({
                sidebarMode: Sidebar.MODE_FLY,
            });
        } else {
            this.setState({
                sidebarMode: Sidebar.MODE_FLUID,
            });
        }
    }

    getWidthInBoundaries(minWidth, maxWidth, width) {
        if (width > 0 && width < minWidth) {
            return minWidth;
        }

        if (width > 0 && width > maxWidth) {
            return maxWidth;
        }

        if (width > 0 && width > maxWidth) {
            return maxWidth;
        }

        return width;
    }

    handleResize(diff) {
        const { maxWidth, minWidth, position } = this.props;
        const { width, isSplit: wasSplit } = this.state;

        const halfWindowWidth = window.innerWidth * 0.5;

        const usedMaxWidth = maxWidth || halfWindowWidth;
        const updatedWidth = position === Sidebar.RIGHT ? width + diff : width - diff;

        const newWidth = this.getWidthInBoundaries(minWidth, usedMaxWidth, updatedWidth);

        const isSplit = newWidth === halfWindowWidth;

        // Check for sidebar with if it is half window size. If it was before but the sidebar was resized so it is
        // no longer walf window size, set the sidebar with to hals window size to avoid jumping sidebar to old width
        this.setState({
            isSplit,
            width: wasSplit && !isSplit ? halfWindowWidth : newWidth,
        });
    }

    handleResizeStart() {
        const body = getSidebarBodyByRef(this.sidebarRef);
        if (body) {
            body.classList.add('pointer-events-none');
        }

        this.setState({ isResize: true });
    }

    handleResizeEnd() {
        const body = getSidebarBodyByRef(this.sidebarRef);
        if (body) {
            body.classList.remove('pointer-events-none');
        }

        this.setState({ isResize: false });
        this.props.onResizeEnd();
    }

    handleFullscreenChange() {
        const { onFullScreenChange } = this.props;
        const { isFullscreen } = this.state;

        const newFullscreenState = !isFullscreen;
        this.setState({ isFullscreen: newFullscreenState });
        onFullScreenChange(newFullscreenState);
    }

    handleEscFullscreen(event) {
        const { disableEsc, enableFullscreenToggle, onFullScreenChange } = this.props;
        const { isFullscreen } = this.state;

        if (!disableEsc && enableFullscreenToggle && isFullscreen && event.keyCode === 27) {
            this.setState({ isFullscreen: false });
            onFullScreenChange(false);
        }
    }

    handlePrevContent() {}

    handleNextContent() {}

    render() {
        const {
            className,
            closed,
            title,
            headerClassName,
            showHeaderBorder,
            titleClassName,
            bodyClassName,
            footer,
            footerClassName,
            onClose,
            children,
            resizable,
            position,
            maxWidth,
            useBackdrop,
            makeBackdropVisible,
            onBackdropClick,
            backdropClassName,
            disableFullscreen,
            enableFullscreenToggle,
            enableNavigationButtons,
            disableClose,
            bodyRef,
            headerButtons,
        } = this.props;

        const { width, isFullscreen, isSplit, isResize } = this.state;

        const enableFullscreenButtons = !isNil(disableFullscreen) ? !disableFullscreen : enableFullscreenToggle;

        logDeprecatedWarnings(deprecatedProps, this.props, 'Sidebar');

        const classes = classNames(
            'Sidebar',
            className,
            closed && 'closed',
            isFullscreen && 'width-100vw sidebar-fullscreen',
            isSplit && !isFullscreen && 'max-width-50vw width-50vw',
            this.state.sidebarMode === Sidebar.MODE_FLY ? 'fly' : isFullscreen ? 'fly' : 'fluid'
        );

        const headerClassNames = classNames('SidebarHeader', headerClassName && headerClassName, showHeaderBorder && 'show-border');

        const titleClassNames = classNames('SidebarTitle', titleClassName && titleClassName);

        const bodyClassNames = classNames('SidebarBody', bodyClassName && bodyClassName);

        const backdropClassNames = classNames(
            'SidebarBackdrop',
            makeBackdropVisible && 'bg-black opacity-50',
            backdropClassName && backdropClassName
        );

        const fullscreenIconClasses = classNames(
            'rioglyph',
            isFullscreen ? 'rioglyph-resize-small' : 'rioglyph-resize-full'
        );

        const resizeLimitClasses = classNames('SidebarResizeLimit', isResize && 'display-block');

        const isRight = position === Sidebar.RIGHT;

        const resizeIndicatorPosition = maxWidth || window.innerWidth * 0.5;
        const resizeLimitStyle = isRight ? { right: resizeIndicatorPosition } : { left: resizeIndicatorPosition };

        return (
            <div className={classes} style={{ width: width }} ref={node => (this.sidebarRef = node)}>
                <div className={resizeLimitClasses} style={resizeLimitStyle} />
                <div className={'SidebarContent'}>
                    <div className={headerClassNames}>
                        <div className={titleClassNames}>{title ? title : ''}</div>
                        <div className={'SidebarButtons non-printable close'}>
                            {headerButtons}
                            {enableNavigationButtons && (
                                <React.Fragment>
                                    <button
                                        className={'btn btn-muted btn-icon-only'}
                                        onClick={this.handlePrevContent}
                                    >
                                        <span className={' rioglyph rioglyph-chevron-left'} />
                                    </button>
                                    <button
                                        className={'btn btn-muted btn-icon-only'}
                                        onClick={this.handleNextContent}
                                    >
                                        <span className={' rioglyph rioglyph-chevron-right'} />
                                    </button>
                                </React.Fragment>
                            )}
                            {enableFullscreenButtons && (
                                <button className={'btn btn-muted btn-icon-only'} onClick={this.handleFullscreenChange}>
                                    <span className={fullscreenIconClasses} />
                                </button>
                            )}
                            {!disableClose && (headerButtons || enableNavigationButtons || enableFullscreenButtons) && (
                                <div className={'SidebarButtons-spacer'} />
                            )}
                            {!disableClose && (
                                <button className={'btn btn-muted btn-icon-only'} onClick={onClose} >
                                    <span className={'rioglyph rioglyph-remove'} />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className={bodyClassNames} ref={bodyRef}>
                        {children}
                    </div>
                    {footer && renderFooter(footer, footerClassName)}
                </div>
                {resizable && (
                    <Resizer
                        onResizeStart={this.handleResizeStart}
                        onResize={this.handleResize}
                        onResizeEnd={this.handleResizeEnd}
                        direction={Resizer.HORIZONTAL}
                        position={isRight ? Resizer.LEFT : Resizer.RIGHT}
                    />
                )}
                {useBackdrop && !closed && <div className={backdropClassNames} onClick={onBackdropClick} />}
            </div>
        );
    }
}

Sidebar.LEFT = 'left';
Sidebar.RIGHT = 'right';

Sidebar.MODE_FLY = 'fly';
Sidebar.MODE_FLUID = 'fluid';

Sidebar.defaultProps = {
    closed: false,
    width: 350,
    minWidth: 100,
    maxWidth: 0,
    resizable: false,
    onResizeEnd: () => {},
    disableEsc: false,
    enableFullscreenToggle: false,
    enableNavigationButtons: false,
    disableClose: false,
    position: Sidebar.LEFT,
    fly: false,
    switchModeBreakpoint: 0,
    onClose: () => {},
    useBackdrop: false,
    openInfullscreen: false,
    onFullScreenChange: () => {},
    makeBackdropVisible: false,
    onBackdropClick: () => {},
    backdropClassName: '',
};

Sidebar.propTypes = {
    closed: PropTypes.bool,
    fly: PropTypes.bool,
    switchModeBreakpoint: PropTypes.number,
    resizable: PropTypes.bool,
    onResizeEnd: PropTypes.func,
    disableEsc: PropTypes.bool,
    position: PropTypes.oneOf([Sidebar.LEFT, Sidebar.RIGHT]),
    // When sidebar is resizable it will take the provided with in px only
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    minWidth: PropTypes.number,
    maxWidth: PropTypes.number,
    className: PropTypes.string,
    headerClassName: PropTypes.string,
    titleClassName: PropTypes.string,
    bodyClassName: PropTypes.string,
    footerClassName: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    onClose: PropTypes.func,
    disableClose: PropTypes.bool,
    footer: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
    fullscreen: PropTypes.bool,
    openInFullscreen: PropTypes.bool,
    onFullScreenChange: PropTypes.func,
    disableFullscreen: PropTypes.bool,
    enableFullscreenToggle: PropTypes.bool,
    enableNavigationButtons: PropTypes.bool,
    useBackdrop: PropTypes.bool,
    makeBackdropVisible: PropTypes.bool,
    onBackdropClick: PropTypes.func,
    backdropClassName: PropTypes.string,
    bodyRef: PropTypes.elementType,
    headerButtons: PropTypes.node,
};

export default Sidebar;
