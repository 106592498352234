import PropTypes from 'prop-types';

import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ApplicationLayout, ApplicationHeader, ActionBarItem, NotificationsContainer } from 'rio-uikit';
import { SessionExpiredDialog } from 'rio-session-expired-info';

import IframeResizer from 'iframe-resizer-react';

export const ApplicationFrame = (props) => {
    const {
        homeRoute,
        appMenuUri,
        userMenuUri,
        userLocale,
        showSessionExpired,
        children,
        onHideSessionDialog,
    } = props;

    const appMenu = (
        <IframeResizer
            src={appMenuUri}
            checkOrigin={false}
            style={{ width: '1px', minWidth: '100%', height: '85px', border: '0' }}/>
    );

    const actionBarItems = [
        <ActionBarItem id='userMenu'>
            <ActionBarItem.Icon>
                <span className='icon rioglyph rioglyph-user'></span>
            </ActionBarItem.Icon>
            <ActionBarItem.Popover useOffscreen>
                <IframeResizer
                    id={'popover-iframe'}
                    src={userMenuUri}
                    checkOrigin={false}
                    style={{ width: '1px', minWidth: '100%', height: '91px', border: '0' }}
                />
            </ActionBarItem.Popover>
        </ActionBarItem>,
    ];
    return (
        <ApplicationLayout>
            <ApplicationLayout.Header>
                <ApplicationHeader
                    homeRoute={<a href={homeRoute}/>}
                    label={<FormattedMessage id='intl-msg:app.title'/>}
                    appNavigator={appMenu}
                    actionBarItems={actionBarItems}/>
            </ApplicationLayout.Header>
            <ApplicationLayout.Body className='responsive'>
                <NotificationsContainer/>
                <SessionExpiredDialog
                    locale={userLocale}
                    onClose={onHideSessionDialog}
                    show={showSessionExpired}/>
                {children}
            </ApplicationLayout.Body>
        </ApplicationLayout>
    );
};

export default ApplicationFrame;

ApplicationFrame.defaultProps = {
};

ApplicationFrame.propTypes = {
    // props
    homeRoute: PropTypes.string,
    appMenuUri: PropTypes.string,
    userMenuUri: PropTypes.string,
    userLocale: PropTypes.string,
    showSessionExpired: PropTypes.bool,
    // functions
    onHideSessionDialog: PropTypes.func,
};
