import { call, put, takeLatest } from 'redux-saga/effects';

import { FETCH_APPS, fetchAppsSuccessful } from '~/features/apps/actions/appsActions';

import { getHTTP } from '~/features/base/sagas/sagaUtil';

import { finishedAction, failedAction } from '~/features/higherorder/transforms/actionTransforms';

import { env } from '~/env';
const { appMenuJsonUri } = env.runtimeConfig;

export function* doFetchAppsSaga(action) {
    try {
        const response = yield call(getHTTP, appMenuJsonUri || '/apps.json');
        const apps = response.apps;
        yield put(fetchAppsSuccessful(apps));
    } catch (error) {
        yield put(failedAction(action.type));
    }
    yield put(finishedAction(action.type));
}

export function* fetchAppsSaga() {
    yield takeLatest(FETCH_APPS, doFetchAppsSaga);
}
