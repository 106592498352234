import React, { useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import activeElement from 'dom-helpers/activeElement';

import { CSSTransition } from 'react-transition-group';
import { getOrCreatePortalRoot } from '../../utils/portalRoot';
import { useEffectAfterMount, useEsc } from '../../utils/hooks';
import DialogHeader from './DialogHeader';
import DialogBody from './DialogBody';
import DialogFooter from './DialogFooter';
import baseDialogPropTypes from './baseDialogPropTypes';

const MODAL_DIALOG_CLASS = 'modal-dialog';
const MODAL_OPEN_CLASS = 'modal-open';

const Dialog = props => {
    const {
        title,
        subtitle,
        body,
        footer,
        headerButtons,
        className,
        bodyClassName,
        footerClassName,
        showCloseButton,
        useOverflow,
        bsSize,
        show,
        onHide,
        disableEsc,
        onEsc,
        onCloseValidation,
    } = props;

    const [open, setOpen] = useState(show);
    const dialogWrapperRef = useRef();

    const modalRoot = getOrCreatePortalRoot();

    const toggleBodyClass = add => {
        // We need to set a body class to fix the -webkit-overflow-scrolling on safari and iOS

        // Remove "modal-open" from body only when there is no other dialog in the DOM
        // in order to support multiple dialogs
        const hasOtherDialogs = modalRoot.getElementsByClassName(MODAL_DIALOG_CLASS).length > 1;

        if (add) {
            document.body.classList.add(MODAL_OPEN_CLASS);
        } else if (!add && !hasOtherDialogs) {
            document.body.classList.remove(MODAL_OPEN_CLASS);
        }
    };

    const handleClose = (usedEscapeKey) => {
        if (onCloseValidation()) {
            setOpen(false);
            onHide();

            usedEscapeKey && onEsc();
        }
    };

    useEffectAfterMount(() => {
        setOpen(show);
        toggleBodyClass(show);
    }, [show]);

    useEsc(() => {
        if (!dialogWrapperRef || !dialogWrapperRef.current) {
            return;
        }

        const dialogElement = dialogWrapperRef.current;
        const currentActiveElement = activeElement(document);

        // Only allow to close the dialog when the focus is inside the dialog
        if (!disableEsc && dialogElement.contains(currentActiveElement)) {
            handleClose(true);
        }
    });

    const handleEntered = () => {
        // Note that in order to focus the dialog itself, the tabindex has to be set on that element
        dialogWrapperRef && dialogWrapperRef.current && dialogWrapperRef.current.focus();
    };

    const modalClasses = classNames('modal', 'show', className);

    const modalDialogClasses = classNames(
        MODAL_DIALOG_CLASS,
        useOverflow && 'modal-overflow',
        bsSize === Dialog.SIZE_SM && 'modal-sm',
        bsSize === Dialog.SIZE_LG && 'modal-lg',
        bsSize === Dialog.SIZE_XL && 'modal-xl',
        bsSize === Dialog.SIZE_FULL && 'modal-full',
        bsSize === Dialog.SIZE_FULL_SCREEN && 'modal-fullscreen'
    );

    const backdropClasses = classNames('modal-backdrop');

    return ReactDOM.createPortal(
        <CSSTransition in={open} timeout={200} classNames={'modal'} unmountOnExit onEntered={handleEntered}>
            <div className={modalClasses} tabIndex={1} role={'dialog'} ref={dialogWrapperRef}>
                <div className={modalDialogClasses} role={'document'}>
                    <div className={'modal-content'}>
                        {title && (
                            <DialogHeader
                                title={title}
                                subtitle={subtitle}
                                headerButtons={headerButtons}
                                showCloseButton={showCloseButton}
                                onClose={handleClose}
                            />
                        )}
                        {body && <DialogBody className={bodyClassName}>{body}</DialogBody>}
                        {footer && <DialogFooter className={footerClassName}>{footer}</DialogFooter>}
                    </div>
                </div>
                <div className={backdropClasses}></div>
            </div>
        </CSSTransition>,
        modalRoot
    );
};

Dialog.SIZE_SM = 'sm';
Dialog.SIZE_MD = 'md'; // default
Dialog.SIZE_LG = 'lg';
Dialog.SIZE_XL = 'xl';
Dialog.SIZE_FULL = 'full';
Dialog.SIZE_FULL_SCREEN = 'fullscreen';

Dialog.defaultProps = {
    show: false,
    onHide: () => {},
    onEsc: () => {},
    className: '',
    showCloseButton: true,
    disableEsc: false,
    useOverflow: false,
    onCloseValidation: () => true,
};

Dialog.propTypes = {
    ...baseDialogPropTypes,
    body: PropTypes.node,
    footer: PropTypes.node,
    headerButtons: PropTypes.node,
    className: PropTypes.string,
    bodyClassName: PropTypes.string,
    footerClassName: PropTypes.string,
    onHide: PropTypes.func,
    onEsc: PropTypes.func,
};

export default Dialog;
