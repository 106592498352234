import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class NumberInput extends Component {
    constructor(props) {
        super(props);
        this.state = this.propsToState(props);
    }

    propsToState(props) {
        const defaultProps = this.getDefaultState();
        const min = Number.isInteger(props.min) ? props.min : defaultProps.min;
        const max = Number.isInteger(props.max) ? props.max : defaultProps.max;

        const value = this.getValueFromProps(props.value, defaultProps.value, max, min);

        const step = this.getStepFromProps(props.step, defaultProps.step, max, min);

        const inputValue = value;
        const isValid = true;

        const type = props.type === 'number' ? 'number' : 'text';

        const bsSize = props.bsSize || defaultProps.bsSize;

        const disabled = props.disabled || defaultProps.disabled;

        return {
            min,
            max,
            value,
            inputValue,
            isValid,
            step,
            type,
            bsSize,
            disabled,
        };
    }

    getDefaultState() {
        return {
            min: 0,
            max: Number.MAX_VALUE,
            value: 0,
            step: 1,
            bsSize: undefined,
            disabled: false,
        };
    }

    getValueFromProps(propValue, defaultValue, max, min) {
        return Number.isInteger(propValue) && propValue <= max && propValue >= min ? propValue : defaultValue;
    }

    getStepFromProps(propStep, defaultStep, max, min) {
        return Number.isInteger(propStep) && propStep < Math.abs(max - min) ? propStep : defaultStep;
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        const isEqual = this.isEqual(nextProps, this.props);
        if (!isEqual) {
            this.setState(this.propsToState(nextProps));
        }
    }

    componentWillUpdate(nextProps, nextState) {
        const isEqual = this.isEqual(nextState, this.state);
        if (
            !isEqual &&
            nextState.isValid &&
            typeof nextState.value === 'number' &&
            typeof nextProps.onValueChanged === 'function'
        ) {
            nextProps.onValueChanged(nextState.value);
        }
    }

    isEqual(nextValue, value) {
        const isValuesEqual = nextValue.value === value.value && nextValue.inputValue === value.inputValue;

        const isIntervalAndStepEqual =
            nextValue.min === value.min && nextValue.max === value.max && nextValue.step === value.step;

        const isTypeAndDisabledEqual = nextValue.type === value.type && nextValue.disabled === value.disabled;

        return isValuesEqual && isIntervalAndStepEqual && isTypeAndDisabledEqual;
    }

    toValidNumber(number) {
        if (number < this.state.min) {
            return this.state.min;
        } else if (number > this.state.max) {
            return this.state.max;
        }
        return number;
    }

    isFormatted(number) {
        return /^(-)?([1-9]\d*)?$/.test(number) || number === '0';
    }

    incrementValue() {
        const currentValue = this.convertNonIntegerToDefault(this.state.value);
        const newValue = currentValue + this.state.step;
        if (newValue <= this.state.max) {
            this.setState({
                value: newValue,
                inputValue: newValue,
                isValid: true,
            });
            return true;
        }
        return false;
    }

    decrementValue() {
        const currentValue = this.convertNonIntegerToDefault(this.state.value);
        const newValue = currentValue - this.state.step;
        if (newValue >= this.state.min) {
            this.setState({
                value: newValue,
                inputValue: newValue,
                isValid: true,
            });
            return true;
        }
        return false;
    }

    handleOnChange(event) {
        const newValue = event.target.value;
        this.applyValue(newValue);
    }

    applyValue(newValue) {
        if (this.isFormatted(newValue)) {
            if (newValue === '-' || newValue === '') {
                this.setState({
                    value: newValue,
                    inputValue: newValue,
                    isValid: true,
                });
            } else {
                const inputValue = Number(newValue);
                const isValid = inputValue >= this.state.min && inputValue <= this.state.max;
                this.setState({
                    value: this.toValidNumber(inputValue),
                    inputValue,
                    isValid,
                });
            }
        }
    }

    convertNonIntegerToDefault(value) {
        let currentValue = value;
        if (!Number.isInteger(currentValue)) {
            currentValue = this.state.min;
        }
        return currentValue;
    }

    handleBlur() {
        const inputValue = this.convertNonIntegerToDefault(Number(this.state.inputValue));
        const validValue = this.toValidNumber(inputValue);
        this.applyValue(validValue);
    }

    render() {
        const { unit, inputAddon, className } = this.props;
        const { type, step, isValid, value, inputValue, disabled, bsSize } = this.state;

        const inputGroupClassNames = classNames(
            'input-group',
            bsSize === 'sm' && 'input-group-sm',
            bsSize === 'lg' && 'input-group-lg'
        );

        const inputClassNames = classNames(
            'form-control',
            'no-controls',
            bsSize === 'sm' && 'input-sm',
            bsSize === 'lg' && 'input-lg',
            className
        );

        const input = (
            <input
                type={type}
                step={step}
                value={isValid ? value : inputValue}
                className={inputClassNames}
                disabled={disabled}
                onBlur={() => this.handleBlur()}
                onChange={event => this.handleOnChange(event)}
            />
        );

        return unit || inputAddon ? (
            <div className={inputGroupClassNames}>
                {inputAddon &&
                    <span className={'input-group-addon'}>
                        <span className={inputAddon}></span>
                    </span>
                }
                {input}
                {unit &&
                    <span className={'input-group-addon'}>
                        {unit}
                    </span>
                }
            </div>
        ) : (
            input
        );
    }
}

NumberInput.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    value: PropTypes.number,
    step: PropTypes.number,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    onValueChanged: PropTypes.func,
    bsSize: PropTypes.oneOf(['sm', 'lg', 'small', 'large']),
    className: PropTypes.string,
    unit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    inputAddon: PropTypes.string,
};
