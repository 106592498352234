import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

export class SubModuleContainer extends PureComponent {

    render() {
        return (
            <div className='device-management-submodule'>
                {this.props.children}
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
    };
};

export const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubModuleContainer);

SubModuleContainer.defaultProps = {
};

SubModuleContainer.propTypes = {
};
