import React from 'react';

export const ApplicationContentWrapper = ({ children }) => (
    // container-fluid fluid-large
    <div className='devicemanagement devicemanagement-frame'>
        <div className='devicemanagement-frame-content'>
            {children}
        </div>
    </div>
);

export default ApplicationContentWrapper;
