
export const toSuccess = actionType => `@@success/${actionType}`;
export const toFailed = actionType => `@@failed/${actionType}`;
export const toFinished = actionType => `@@finished/${actionType}`;

export const successAction = (actionType) => ({
    type: toSuccess(actionType),
});

export const finishedAction = (actionType) => ({
    type: toFinished(actionType),
});

export const failedAction = (actionType, error) => ({
    type: toFailed(actionType),
    error,
});
