import React from 'react';
import PropTypes from 'prop-types';
import Dialog from './Dialog';

/*
 * Confirmation dialog component to confirm or cancel an action
 */
const ConfirmationDialog = props => {
    const {
        content,
        onClickCancel,
        cancelButtonText,
        onClickConfirm,
        confirmButtonText,
        disableConfirm,
    } = props;

    const footer = (
        <div>
            <button type={'button'} className={'CancelButton btn btn-default'} onClick={onClickCancel}>
                {cancelButtonText}
            </button>
            <button
                type={'button'}
                className={'ConfirmationButton btn btn-primary'}
                onClick={onClickConfirm}
                disabled={disableConfirm}
            >
                {confirmButtonText}
            </button>
        </div>
    );

    return (
        <Dialog
            {...props}
            body={content}
            footer={footer}
            onHide={onClickCancel}
            showCloseButton={false}
            className={'confirmation-dialog'}
            disableEsc
        />
    );
};

ConfirmationDialog.defaultProps = {
    show: false,
    onClickCancel: () => {},
    onClickConfirm: () => {},
};

ConfirmationDialog.propTypes = {
    show: PropTypes.bool.isRequired,
    title: PropTypes.node.isRequired,
    subtitle: PropTypes.node,
    content: PropTypes.node.isRequired,
    onClickCancel: PropTypes.func.isRequired,
    onClickConfirm: PropTypes.func.isRequired,
    cancelButtonText: PropTypes.node.isRequired,
    confirmButtonText: PropTypes.node.isRequired,
    disableConfirm: PropTypes.bool,
    bsSize: PropTypes.string,
};

export default ConfirmationDialog;
