import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';
import classname from 'classnames';

export default class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.handleToggleKeyDown = this.handleToggleKeyDown.bind(this);
    }

    componentDidMount() {
        this.update(this.props.indeterminate);
    }

    // eslint-disable-next-line camelcase
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.setState(() => ({
                checked: this.props.checked,
            }));
        }
        this.update(nextProps.indeterminate);
    }

    update(indeterminate) {
        this.getInput().indeterminate = indeterminate;
    }

    getInput() {
        return ReactDom.findDOMNode(this).children[0];
    }

    handleToggleKeyDown(event) {
        switch (event.keyCode) {
            case 32:
                // toggle on space
                this.toggle(event);
                break;
            case 13:
                // open on enter
                this.toggle(event);
                break;
            default:
                break;
        }
    }

    toggle(event) {
        event.preventDefault();

        if (this.props.disabled) {
            return;
        }

        const input = this.getInput();

        if (input.indeterminate) {
            input.indeterminate = false;
        }

        input.checked = !input.checked;

        // For testing capabilities
        this.checked = input.checked;

        this.props.onClick(event);
        this.props.onChange(event);
    }

    renderCheckboxText(text) {
        return (
            <span className={'checkbox-text'}>
                <span>{text}</span>
            </span>
        );
    }

    renderCheckboxIcon(icon, iconSize, iconLabelPosition, text) {
        const iconStyles = {
            width: `${iconSize}px`,
            height: `${iconSize}px`,
            fontSize: `${iconSize}px`,
            lineHeight: `${iconSize}px`,
        };

        return (
            <span className={`checkbox-icon label-${iconLabelPosition}`}>
                <span className={`rioglyph ${icon}`} style={iconStyles} />
                <span className={'checkbox-label'}>{text}</span>
            </span>
        );
    }

    render() {
        const {
            disabled,
            inline,
            label,
            children,
            error,
            size,
            inputRef,
            className,
            required,
            indeterminate,
            tabIndex,
            onChange,
            onClick,
            checked,
            defaultChecked,
            name,
            icon,
            iconSize,
            iconLabelPosition,
            id = name,
        } = this.props;

        const text = label || children;
        const labelClassnames = classname('checkbox', inline && 'checkbox-inline', className);
        const inputClassnames = classname(
            error && 'error',
            size === 'large' && 'large',
            indeterminate && 'indeterminate',
            className
        );

        return (
            <label className={labelClassnames} tabIndex={tabIndex} htmlFor={id} onKeyDown={this.handleToggleKeyDown}>
                <input
                    id={id}
                    name={name}
                    type={'checkbox'}
                    checked={checked}
                    required={required}
                    defaultChecked={defaultChecked}
                    disabled={disabled}
                    className={inputClassnames}
                    onClick={onClick}
                    onChange={onChange}
                    ref={inputRef}
                />
                {icon
                    ? this.renderCheckboxIcon(icon, iconSize, iconLabelPosition, text)
                    : this.renderCheckboxText(text)}
            </label>
        );
    }
}

Checkbox.ICON_LABEL_VERTICAL = 'vertical';
Checkbox.ICON_LABEL_HORIZONTAL = 'horizontal';

Checkbox.defaultProps = {
    inline: false,
    error: false,
    disabled: false,
    indeterminate: false,
    required: false,
    tabIndex: 0,
    icon: '',
    iconSize: 16,
    iconLabelPosition: Checkbox.ICON_LABEL_VERTICAL,
    onClick: () => {},
    onChange: () => {},
};

Checkbox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    icon: PropTypes.string,
    iconSize: PropTypes.number,
    iconLabelPosition: PropTypes.oneOf([Checkbox.ICON_LABEL_VERTICAL, Checkbox.ICON_LABEL_HORIZONTAL]),
    onClick: PropTypes.func.isRequired,
    onChange: PropTypes.func,
    checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    defaultChecked: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    indeterminate: PropTypes.bool,
    className: PropTypes.string,
    inline: PropTypes.bool,
    error: PropTypes.bool,
    size: PropTypes.string,
    inputRef: PropTypes.func,
    tabIndex: PropTypes.number,
};
