import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { LoadMoreProgress } from './LoadMoreProgress';

export const LoadMoreButton = props => {
    const { loaded, total, onLoadMore, loadMoreMessage, noMoreMessage, className, ...remainingProps } = props;

    const wrapperClassNames = classNames(
        'LoadMoreButton',
        'align-items-center',
        'display-flex',
        'justify-content-center',
        'margin-top-25',
        'margin-bottom-25',
        'non-printable',
        className
    );

    const hasMoreToLoad = loaded > 0 && total > 0 && loaded !== total;

    return (
        <div {...remainingProps} className={wrapperClassNames}>
            <div className={'min-width-150'}>
                <LoadMoreProgress loaded={loaded} total={total} />
                <div className={'display-flex justify-content-center'}>
                    {hasMoreToLoad && (
                        <button
                            className={'btn btn-link padding-right-20 padding-top-0'}
                            type={'button'}
                            onClick={onLoadMore}
                        >
                            <span className={'rioglyph rioglyph-arrow-down'} />
                            {loadMoreMessage}
                        </button>
                    )}
                    {!hasMoreToLoad && <span className={'padding-top-4 padding-bottom-10'}>{noMoreMessage}</span>}
                </div>
            </div>
        </div>
    );
};

LoadMoreButton.defaultProps = {
    onLoadMore: () => {},
    className: '',
    loadMoreMessage: '',
    noMoreMessage: '',
};

LoadMoreButton.propTypes = {
    loaded: PropTypes.number,
    total: PropTypes.number,
    loadMoreMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    noMoreMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    onLoadMore: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default LoadMoreButton;
