import React, { PureComponent } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import routes from '~/features/base/routes/index';

import AppContainer from '~/features/base/components/AppContainer';

export class MainContainer extends PureComponent {
    render() {
        return (
            <AppContainer>
                <Switch>
                    <Route path={'/'} component={routes}/>
                    <Redirect to={'/'} />
                    <Redirect to='/error'/>
                </Switch>
            </AppContainer>
        );
    }
}

export default MainContainer;
