export const FETCH_APPS = 'FETCH_APPS';
export const fetchApps = () => ({
    type: FETCH_APPS,
});

export const FETCH_APPS_SUCCESSFUL = 'FETCH_APPS_SUCCESSFUL';
export const fetchAppsSuccessful = (apps) => ({
    type: FETCH_APPS_SUCCESSFUL,
    apps,
});
