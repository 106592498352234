import { FETCH_APPS_SUCCESSFUL } from '~/features/apps/actions/appsActions';

const defaultState = [];

export const appsReducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case FETCH_APPS_SUCCESSFUL:
            return action.apps;
        default:
            return state;
    }
};

export default appsReducer;
