import PropTypes from 'prop-types';
import React, { useState } from 'react';
import onClickOutside from 'react-onclickoutside';
import classNames from 'classnames';

const ToggleButton = props => {
    const { title, caret } = props;
    return (
        <a
            className={classNames('ModuleNavigation-dropdown dropdown-toggle justify-content-between', {
                inactive: !caret,
            })}
            role='button'
            id='basic-nav-dropdown'
        >
            <span>{title}</span>
            {caret && <span className='caret' />}
        </a>
    );
};

const DropdownMenu = props => {
    const { children } = props;
    if (!children && !children.length) {
        return null;
    }

    return (
        <ul role='menu' className='dropdown-menu'>
            {children}
        </ul>
    );
};

/*
 * The onClickOutside HOC cannot be on the top level anymore as we have to provide
 * handleClickOutside as prop.
 */
const AppMenuDropdownOpener = onClickOutside(props => {
    const { showAsOpen, children, onDropdownClick } = props;
    return (
        <li className={classNames('dropdown', { open: showAsOpen })} onClick={onDropdownClick}>
            {children}
        </li>
    );
});

export const AppMenuDropdown = props => {
    const { title, caret, children } = props;

    const [isOpen, setIsOpen] = useState(false);
    const handleDropdownClick = () => setIsOpen(!isOpen);
    const handleClickOutside = () => {
        setIsOpen(false);
    };
    const openWithChildren = children && children.length && isOpen;
    return (
        <AppMenuDropdownOpener
            showAsOpen={openWithChildren}
            onDropdownClick={handleDropdownClick}
            handleClickOutside={handleClickOutside}
        >
            <ToggleButton title={title} caret={caret} />
            <DropdownMenu>{children}</DropdownMenu>
        </AppMenuDropdownOpener>
    );
};

export default AppMenuDropdown;

AppMenuDropdown.defaultProps = {
    caret: false,
};

AppMenuDropdown.propTypes = {
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    caret: PropTypes.bool,
};
