import defaults from 'lodash/fp/defaults';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { getUserProfile } from '~/features/login/selectors';

import { env } from '~/env';
const { logoutUri } = env.runtimeConfig;

export class UserMenu extends PureComponent {

    componentDidMount() {
        this.sendDocumentSize();
    }

    componentDidUpdate() {
        this.sendDocumentSize();
    }

    sendDocumentSize() {
        if (document.referrer) {
            const height = document.body.clientHeight;
            window.parent.postMessage({ height: height }, document.referrer);
        }
    }

    broadcastLogout() {
        window.parent.location.href = logoutUri;
    }

    render() {
        const { userProfile } = this.props;
        return (
            <div className='bg-white padding-bottom-5 height-100px user-select-none'>
                <div className='padding-bottom-10 text-color-dark'>
                    <div><b>{userProfile.name}</b></div>
                    <div>{userProfile.upn}</div>
                </div>
                <hr className='margin-top-5 margin-bottom-5'></hr>
                <div className='display-block padding-top-5 padding-bottom-0 ellipsis-1'>
                    <a class='btn-block text-color-dark' onClick={this.broadcastLogout}>
                        <span class='rioglyph rioglyph-off margin-right-5'></span>{'Logout'}
                    </a>
                </div>
            </div>
        );
    }
}

export const mapStateToProps = (state) => ({
    userProfile: defaults({
        name: 'Profile contains no name',
        upn: 'Profile contains no upn',
    }, getUserProfile(state)),
});

export default connect(mapStateToProps)(UserMenu);

UserMenu.defaultProps = {
};

UserMenu.propTypes = {
    userProfile: PropTypes.object,
};
