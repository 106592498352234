import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { OverlayTrigger } from 'react-bootstrap';

import Tooltip from '../tooltip/Tooltip';

const ACTIVE_CLASS_NAME = 'active';
const SELECTED_CLASS_NAME = 'selected';

const LABEL_TOOLTIP_DELAY = 1500;
const LABEL_TOOLTIP_PLACEMENT = 'right';

const TreeSidebarCategories = ({ children, currentCategoryId, onSelectCategory }) => {
    return children.map(child => {
        const id = child.props.id;
        const icon = child.props.icon || '';
        const label = child.props.label;

        const statusClassName = classNames(
            currentCategoryId === id ? ACTIVE_CLASS_NAME : '',
            child.props.hasSelection && SELECTED_CLASS_NAME
        );

        const handleSelectCategory = event => {
            event.stopPropagation();
            onSelectCategory(id);
        };

        const category = (
            <li key={id} onClick={handleSelectCategory} className={statusClassName}>
                <div className={'selection-bubble'}>
                    <span className={`rioglyph ${icon}`}></span>
                </div>
            </li>
        );

        if (label) {
            const tooltip = <span>{label}</span>;
            return (
                <OverlayTrigger
                    key={`category-tooltip-${id}`}
                    placement={LABEL_TOOLTIP_PLACEMENT}
                    delayShow={LABEL_TOOLTIP_DELAY}
                    overlay={
                        <Tooltip id={'tooltip'} className={'right-top'} width={'auto'}>
                            {tooltip}
                        </Tooltip>
                    }
                >
                    {category}
                </OverlayTrigger>
            );
        }

        return category;
    });
};

const TreeSidebar = React.memo(props => {
    const { className, onClick, ...remainingProps } = props;

    const classes = classNames('TreeSidebar', className);

    return (
        <div className={classes}>
            <ul className={'TreeSidebarNavigation'} onClick={onClick}>
                <TreeSidebarCategories {...remainingProps} />
            </ul>
            <div className={'TreeSidebarToggle'} onClick={onClick}>
                <span className={'rioglyph rioglyph-chevron-left'}></span>
            </div>
        </div>
    );
});

TreeSidebar.displayName = 'TreeSidebar';

TreeSidebar.defaultProps = {
    onClick: () => {},
};

TreeSidebar.propTypes = {
    currentCategoryId: PropTypes.string,
    children: PropTypes.arrayOf(PropTypes.node),
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default TreeSidebar;
