import throttle from 'lodash/fp/throttle';

// As "cssuseragent" is accessing the html dom object directly without checking whether it exists or not, we need to
// use old shool require() to have a conditional import only when html really exists. This avoids issues when
// running the UIKIT in a web-worker
if (document && document.documentElement) {
    // eslint-disable-next-line global-require
    require('cssuseragent');
}

const isDefined = val => val !== undefined && val !== null;

// see https://patrickhlauke.github.io/touch/touchscreen-detection/ for reference
export const hasTouch = () => {
    if (window.PointerEvent && isDefined(navigator.maxTouchPoints)) {
        // if Pointer Events are supported, just check maxTouchPoints
        if (navigator.maxTouchPoints > 0) {
            return true;
        }
    } else if (window.matchMedia && window.matchMedia('(any-pointer:coarse)').matches) {
        // no Pointer Events...
        // check for any-pointer:coarse which mostly means touchscreen
        return true;
    } else if (window.TouchEvent || isDefined(window.ontouchstart)) {
        // last resort - check for exposed touch events API / event handler
        return true;
    }

    return false;
};

export const isDesktop = () => document && document.documentElement.classList.contains('ua-desktop');

export const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

// https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
const calcViewportUnits = () => {
    const vh = window.innerHeight * 0.01;
    return `--vh: ${vh}px`;
};

export const documentBootstrapping = (function (document) {
    if (!document || !document.documentElement) {
        return;
    }

    const html = document.documentElement;
    const setViewportUnits = () => {
        // eslint-disable-next-line max-len
        // https://stackoverflow.com/questions/43054620/strict-mode-error-only-in-ie11-can-i-take-off-strict-mode-just-for-this-browser?rq=1
        // IE11 does not allow to set style directly as it's read only
        //html.style = calcViewportUnits();
        html.setAttribute('style', calcViewportUnits());
    };

    window.addEventListener('orientationchange', throttle(1000, setViewportUnits));
    window.addEventListener('resize', throttle(1000, setViewportUnits));

    setViewportUnits();

    if (inIframe()) {
        if (html) {
            html.classList.add('window-iframe');
        }
    }
})(document);
