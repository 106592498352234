import cond from 'lodash/fp/cond';
import constant from 'lodash/fp/constant';
import matches from 'lodash/fp/matches';
import noop from 'lodash/fp/noop';
import otherwise from 'lodash/fp/stubTrue';

import { configureEnv } from '../env';

export function configureReporting(window = window, process = process) {
    const {
        isProdBuild,
        isRunningOnProd,
        isRunningOnStaging,
        runsInSandbox,
    } = configureEnv(window, process);

    const justLogException = error => {
        console.error('Exception captured:', error);

        const query = encodeURIComponent(error.message);
        console.info(
            'Let me StackOverflow that for you:',
            `https://stackoverflow.com/search?q=${query}`
        );
        console.info(
            'Let me Google that for you:',
            `https://google.com/search?q=${query}`
        );
    };

    const logToConsoleAndReport = error => {
        justLogException(error);
    };

    const state = {
        isProdBuild,
        isRunningOnProd,
        isRunningOnStaging,
        runsInSandbox,
    };

    const captureException = cond([
        [matches({ isRunningOnStaging: true }), constant(logToConsoleAndReport)],
        [matches({ runsInSandbox: true }), constant(logToConsoleAndReport)],
        [otherwise, constant(justLogException)],
    ])(state);

    const captureSagaCancelledMiddleware = (/* store */) => (next) => cond([
        [matches({ type: 'report/SAGA_CANCELLED' }), ({ payload }) => captureException(payload)],
        [otherwise, next],
    ]);

    return {
        captureException,
        captureSagaCancelledMiddleware,
    };
}
