import React, { Component } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import classNames from 'classnames';
import ClearableInput from '../clearableInput/ClearableInput';

const filterMenuItems = (items, value) => {
    return items.map((menuGroupItem) => {
        const groupItems = menuGroupItem.navItems;
        const navItems = groupItems.filter(item => item.key.toLowerCase().includes(value.toLowerCase()));
        return {
            ...menuGroupItem,
            navItems,
        };
    });
};

const ListMenuHeader = ({ group, className = '', groupNavItem }) => {
    if (!group && !groupNavItem) {
        return null;
    }

    return (
        <li className='ListMenuHeader'>
            {groupNavItem ? groupNavItem : <span className={className}>{group}</span>}
        </li>
    );
};

const ListMenuItem = ({ disabled, children }) => (
    <li className={disabled ? 'disabled' : ''}>{children}</li>
);

const ListMenuGroup = ({ menuGroup, className }) => {
    if (!menuGroup.navItems.length) {
        return null;
    }

    return (
        <ul className={`ListMenuGroup ${className}`}>
            {<ListMenuHeader {...menuGroup} />}
            {menuGroup.navItems.map((item) =>
                <ListMenuItem
                    key={item.key}
                    disabled={item.disabled}
                >
                    {item.item}
                </ListMenuItem>)}
        </ul>
    );
};

const NotFoundMessage = ({ notFoundMessage }) => (
    <div className='padding-top-25 text-center text-color-gray'>
        {notFoundMessage}
    </div>
);

export default class ListMenu extends Component {

    constructor(props) {
        super(props);

        this.state = {
            filterValue: '',
            menuItems: this.props.menuItems,
        };

        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.menuItems !== state.menuItems) {
            const newState = {
                ...state,
                menuItems: filterMenuItems(props.menuItems, state.filterValue),
            };
            return newState;
        }
        return state;
    }

    componentDidMount() {
        const { focusFilter } = this.props;

        const addEventListener = (event, callback) => {
            window.addEventListener(event, callback);
            return {
                remove() {
                    window.removeEventListener(event, callback);
                },
            };
        };

        this.keydownListener = addEventListener('keydown', this.handleKeyDown);

        if (focusFilter && this.inputRef) {
            this.inputRef.focus();
        }
    }

    componentWillUnmount() {
        this.keydownListener.remove();
    }

    handleKeyDown(event) {
        if (event.keyCode === 27 &&
            this.props.enableFilter &&
            this.inputRef &&
            this.inputRef === document.activeElement) {
            // clear on esc
            this.setState(() => ({
                filterValue: '',
            }));
        }
    }

    handleClear() {
        const { focusFilter } = this.props;
        if (focusFilter && this.inputRef) {
            this.inputRef.focus();
        }
    }

    handleFilterChange(value) {
        this.setState(() => ({
            filterValue: value,
            menuItems: filterMenuItems(this.props.menuItems, value),
        }));
    }

    hasMenuItems(menuItems) {
        return menuItems.find(menuGroup => !isEmpty(menuGroup.navItems));
    }

    render() {
        const {
            enableFilter,
            filterPlaceholder,
            notFoundMessage,
            className,
            groupClassName,
        } = this.props;

        const formClassNames = classNames(
            'from-group',
            'padding-left-15',
            'padding-right-15',
            'padding-bottom-15',
            'position-sticky',
            'top-0'
        );

        return (
            <div className={`ListMenu ${className}`}>
                {enableFilter &&
                    <div className={formClassNames}>
                        <div className='input-group width-100pct'>
                            <span className='input-group-addon'>
                                <span className='rioglyph rioglyph-search' aria-hidden='true'></span>
                            </span>
                            <ClearableInput
                                value={this.state.filterValue}
                                inputRef={node => (this.inputRef = node)}
                                placeholder={filterPlaceholder}
                                onChange={this.handleFilterChange}
                                onClear={this.handleClear}/>
                        </div>
                    </div>
                }
                {!this.hasMenuItems(this.state.menuItems) &&
                    <NotFoundMessage notFoundMessage={notFoundMessage} />
                }
                {this.state.menuItems.map((menuGroup, index) =>
                    <ListMenuGroup className={groupClassName} menuGroup={menuGroup} key={index} />
                )}
            </div>
        );
    }
}

ListMenu.defaultProps = {
    menuItems: [],
    enableFilter: false,
    focusFilter: false,
    className: '',
    groupClassName: '',
};

ListMenu.propTypes = {
    menuItems: PropTypes.arrayOf(PropTypes.shape({
        group: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.node,
        ]),
        groupNavItem: PropTypes.node,
        navItems: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            item: PropTypes.node.isRequired,
            disabled: PropTypes.bool,
        })).isRequired,
    })).isRequired,
    enableFilter: PropTypes.bool,
    focusFilter: PropTypes.bool,
    filterPlaceholder: PropTypes.string,
    notFoundMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
    ]),
    groupClassName: PropTypes.string,
    className: PropTypes.string,
};
