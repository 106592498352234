// This is the runtime configuration being used for local development
// where authorization is usually mocked

export const localConfig = {
    id: 'env.local',
    backend: {
    },
    homeRoute: `${window.location.origin}`,
    appMenuUri: `${window.location.origin}/#app-menu`,
    userMenuUri: `${window.location.origin}/#user-menu`,
    login: {
        authority: `${window.location.origin}/oauth`,
        mockAuthorization: true,
        mockLocale: 'de-DE',
        preventRedirect: true,
        redirectUri: `${window.location.origin}/redirect.html`,
        silentRedirectUri: `${window.location.origin}/redirect.html`,
    },
    logoutUri: `${window.location.origin}`,
};

