import { useEffect, useRef } from 'react';

export const useEffectOnce = callback => {
    useEffect(callback, []);
};

export const useEffectOnMount = (callback, deps) => {
    const componentJustMountedRef = useRef(true);

    useEffect(() => {
        if (componentJustMountedRef.current) {
            return callback();
        }
        componentJustMountedRef.current = true;
    }, deps);
};

export const useEffectAfterMount = (callback, deps) => {
    const componentJustMountedRef = useRef(true);

    useEffect(() => {
        if (!componentJustMountedRef.current) {
            return callback();
        }
        componentJustMountedRef.current = false;
    }, deps);
};

export const useWindowResizeListener = onResizeCallback => {
    useEffectOnce(() => {
        window.addEventListener('resize', onResizeCallback, false);
        return () => window.removeEventListener('resize', onResizeCallback, true);
    });
};

export const useKeyDownListener = (callback, node = document) => {
    useEffectOnce(() => {
        const event = 'keydown';
        node.addEventListener(event, callback);
        return () => node.removeEventListener(event, callback);
    });
};

export const useEsc = callback => {
    const handelEsc = event => {
        return event.keyCode === 27 && callback(event);
    };
    useKeyDownListener(handelEsc);
};
