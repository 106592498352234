import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import isNil from 'lodash/fp/isNil';

import getMenuItems from '../menuItems/getMenuItems';
import MenuItems from '../menuItems/MenuItems';
import menuItemsPropTypes from './menuItemsPropTypes';
import getDropDirection from '../../utils/getDropDirection';
import DropdownToggleButton from './DropdownToggleButton';
import SplitCaretButton from './SplitCaretButton';
import Caret from './Caret';

class ButtonDropdown extends PureComponent {
    constructor(props) {
        super(props);

        this.isUncontrolled = isNil(props.open);

        this.state = {
            open: this.isUncontrolled && props.open,
            dropup: props.dropup,
            pullRight: props.pullRight,
        };

        this.toggleOpen = this.toggleOpen.bind(this);
        this.handleSplitLabelButtonClick = this.handleSplitLabelButtonClick.bind(this);

        this.refDropdownMenu = React.createRef();
        this.refDropdownToggle = React.createRef();
    }

    isAutoDropActive() {
        return !(!this.props.autoDropDirection || this.props.dropup || this.props.pullRight);
    }

    toggleOpen(event) {
        const { open } = this.isUncontrolled ? this.state : this.props;

        if (open) {
            this.closeMenu();
        } else {
            this.openMenu(event);
        }
    }

    openMenu(event) {
        const toggleNode = this.refDropdownToggle.current;
        const menuNode = this.refDropdownMenu.current;

        const dropDirection = this.isAutoDropActive() ? getDropDirection(toggleNode, menuNode) : {};

        if (this.isUncontrolled) {
            this.setState({
                open: true,
                ...dropDirection,
            });
        } else {
            this.setState({
                ...dropDirection,
            });
        }

        this.props.enableOnClickOutside();
        this.props.onOpen(event);
    }

    closeMenu() {
        if (this.isUncontrolled) {
            this.setState({
                open: false,
            });
        }

        this.props.disableOnClickOutside();
        this.props.onClose();
    }

    handleClickOutside() {
        this.closeMenu();
    }

    handleSplitLabelButtonClick() {
        this.closeMenu();

        if (this.props.onLabelButtonClick) {
            this.props.onLabelButtonClick();
        }
    }

    isDropUp() {
        return this.isAutoDropActive() ? this.state.dropup : this.props.dropup;
    }

    isPullRight() {
        return this.isAutoDropActive() ? this.state.pullRight : this.props.pullRight;
    }

    shouldShowCaret() {
        const { iconOnly, noCaret, splitButton } = this.props;
        return !noCaret && !splitButton && !iconOnly;
    }

    isOpen() {
        const { open } = this.isUncontrolled ? this.state : this.props;
        return open;
    }

    render() {
        const {
            id,
            items,
            bsSize,
            bsStyle,
            disabled,
            iconOnly,
            title,
            splitButton,
            customDropdown,
            toggleClassName,
            dropdownClassName,
            className,
        } = this.props;

        const wrapperClasses = classNames(
            'dropdown',
            'btn-group',
            this.isOpen() && 'open',
            this.isDropUp() && 'dropup',
            className
        );

        const handleDropdownButtonClick = splitButton ? this.handleSplitLabelButtonClick : this.toggleOpen;

        return (
            <div className={wrapperClasses}>
                <DropdownToggleButton
                    id={id}
                    splitButton={splitButton}
                    bsStyle={bsStyle}
                    bsSize={bsSize}
                    iconOnly={iconOnly}
                    disabled={disabled}
                    ref={this.refDropdownToggle}
                    onClick={handleDropdownButtonClick}
                    className={toggleClassName}
                >
                    <React.Fragment>
                        {title}
                        {this.shouldShowCaret() && <Caret />}
                    </React.Fragment>
                </DropdownToggleButton>
                {splitButton && (
                    <SplitCaretButton
                        id={id}
                        bsStyle={bsStyle}
                        disabled={disabled}
                        className={toggleClassName}
                        onClick={this.toggleOpen}
                    />
                )}
                <MenuItems className={dropdownClassName} pullRight={this.isPullRight()} ref={this.refDropdownMenu}>
                    {customDropdown ? customDropdown : getMenuItems(items, this.toggleOpen)}
                </MenuItems>
            </div>
        );
    }
}

ButtonDropdown.defaultProps = {
    id: Math.random().toString(36).substr(2, 16),
    items: [],
    iconOnly: false,
    noCaret: false,
    pullRight: false,
    splitButton: false,
    dropup: false,
    autoDropDirection: true,
    bsStyle: 'default',
    disabled: false,
    onOpen: () => {},
    onClose: () => {},
    toggleClassName: '',
    className: '',
};

ButtonDropdown.propTypes = {
    id: PropTypes.string,
    title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
    open: PropTypes.bool,
    dropup: PropTypes.bool,
    pullRight: PropTypes.bool,
    bsSize: PropTypes.oneOf(['xs', 'sm', 'lg']),
    bsStyle: PropTypes.oneOf(['default', 'primary', 'info', 'warning', 'danger', 'success', 'link', 'muted']),
    iconOnly: PropTypes.bool,
    noCaret: PropTypes.bool,
    splitButton: PropTypes.bool,
    autoDropDirection: PropTypes.bool,
    items: menuItemsPropTypes.isRequired,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    dropdownClassName: PropTypes.string,
    toggleClassName: PropTypes.string,
    onLabelButtonClick: PropTypes.func,
    customDropdown: PropTypes.node,
    enableOnClickOutside: PropTypes.func,
    disableOnClickOutside: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
};

const EnhancedComponent = onClickOutside(ButtonDropdown);

export default function Container(props) {
    return <EnhancedComponent {...props} disableOnClickOutside={true} />;
}
