import map from 'lodash/fp/map';

import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { TeaserContainer, Teaser } from 'rio-uikit';

import { fetchApps } from '~/features/apps/actions/appsActions';

import { getApps } from '~/features/apps/selectors/appsSelectors';

import { fixUrl } from '~/features/apps/utils/appsUtils';

export class StartScreen extends PureComponent {
    constructor(props) {
        super(props);
        props.fetchApps();
    }

    render() {
        const { apps } = this.props;
        return (
            <div className='container'>
                <TeaserContainer>
                    {this.renderApps(apps)}
                </TeaserContainer>
            </div>
        );

    }
    renderApp(app) {
        return (
            <a className='hover-text-decoration-none' href={fixUrl(app.url)}>
                <Teaser
                    headline={<span className='text-color-highlight'><FormattedMessage id={app.name}/></span>}
                    content={<FormattedMessage id={app.subtitle}/>}
                    image={{ src: app.teaser, aspectRatio: '3:1' }}/>
            </a>
        );
    }

    renderApps(apps) {
        return map(this.renderApp, apps);
    }
}

export const mapStateToProps = (state) => ({
    apps: getApps(state),
});

export const mapDispatchToProps = (dispatch) => ({
    fetchApps: () => dispatch(fetchApps()),
});

export default connect(mapStateToProps, mapDispatchToProps)(StartScreen);

StartScreen.defaultProps = {
    apps: [],
};

StartScreen.propTypes = {
    apps: PropTypes.array,
};
